/* eslint-disable react/no-unescaped-entities */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import checkout from "../../../assets/images/stayass/checkout.jpg";
import elevators from "../../../assets/images/stayass/Elevators.jpg";
import frontdesk from "../../../assets/images/stayass/frontdesk.jpg";
import landv from "../../../assets/images/stayass/laundry&vending.jpg";
import lobby from "../../../assets/images/stayass/Lobby.jpg";
import room from "../../../assets/images/stayass/Room.jpg";
import stayass from "../../../assets/images/stayass/stayassured.jpg";

// About Us page sections
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
// import bgImage from "assets/images/stayass/Stay Assured.jpg";

function StayConfident() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/",
          label: "Login",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${stayass})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Stay Assured
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              safe, healthy & comfortable throughout your stay.{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={6}>
          <MKBox display="flex" flexDirection="column" justifyContent="center" textAlign="center">
            <MKTypography variant="h3" mt={1} mb={3}>
              Our Tips for a Safe, Healthy, and Comfortable Stay
            </MKTypography>
          </MKBox>
          <Container>
            <Grid container spacing={6} direction="column" alignItems="center">
              {/* Section 1 */}
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Front Desk
                  </MKTypography>
                  {/* Description */}
                  <MKTypography
                    variant="body2"
                    mb={4}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    All high-touch surfaces, including computer terminals and credit card readers,
                    are regularly cleaned and disinfected. A protective shield has been placed at
                    the front desk.
                  </MKTypography>
                  {/* Image */}
                  <MKBox
                    component="img"
                    src={frontdesk}
                    alt="Front Desk"
                    sx={{
                      width: "80%", // Image takes up 80% of the container width
                      maxWidth: "1200px", // Limit max width for large screens
                      display: "block",
                      mx: "auto", // Center the image horizontally
                    }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Lobby
                  </MKTypography>
                  {/* Description */}
                  <MKTypography
                    variant="body2"
                    mb={4}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    All frequently touched hard surfaces, including doorknobs and handles, are
                    cleaned and disinfected.
                  </MKTypography>
                  {/* Image */}
                  <MKBox
                    component="img"
                    src={lobby}
                    alt="Front Desk"
                    sx={{
                      width: "80%", // Image takes up 80% of the container width
                      maxWidth: "1200px", // Limit max width for large screens
                      display: "block",
                      mx: "auto", // Center the image horizontally
                    }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Elevators
                  </MKTypography>
                  {/* Description */}
                  <MKTypography
                    variant="body2"
                    mb={4}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    All buttons and panel controls are carefully cleaned and disinfected.
                  </MKTypography>
                  {/* Image */}
                  <MKBox
                    component="img"
                    src={elevators}
                    alt="Front Desk"
                    sx={{
                      width: "80%", // Image takes up 80% of the container width
                      maxWidth: "1200px", // Limit max width for large screens
                      display: "block",
                      mx: "auto", // Center the image horizontally
                    }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Room
                  </MKTypography>
                  {/* Description */}
                  <MKTypography
                    variant="body2"
                    mb={4}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    All rooms are thoroughly inspected and approved by management before check-in as
                    part of our 10-step cleaning process. You have the option to choose when and how
                    your room is cleaned.
                  </MKTypography>
                  {/* Image */}
                  <MKBox
                    component="img"
                    src={room}
                    alt="Front Desk"
                    sx={{
                      width: "80%", // Image takes up 80% of the container width
                      maxWidth: "1200px", // Limit max width for large screens
                      display: "block",
                      mx: "auto", // Center the image horizontally
                    }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Laundry & Vending
                  </MKTypography>
                  {/* Description */}
                  <MKTypography
                    variant="body2"
                    mb={4}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    All machines, frequently touched surfaces, and doorknobs/handles are thoroughly
                    cleaned and disinfected.
                  </MKTypography>
                  {/* Image */}
                  <MKBox
                    component="img"
                    src={landv}
                    alt="Front Desk"
                    sx={{
                      width: "80%", // Image takes up 80% of the container width
                      maxWidth: "1200px", // Limit max width for large screens
                      display: "block",
                      mx: "auto", // Center the image horizontally
                    }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  {/* Title */}
                  <MKTypography
                    variant="h3"
                    component="h2"
                    mb={2}
                    sx={{
                      width: "60%",
                      mx: "auto", // Center the text horizontally
                    }}
                  >
                    Check out feeling happy and healthy
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default StayConfident;
