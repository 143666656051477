/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

const PrivacyPolicy = () => {
  return (
    <>
      <MKBox
        position="fixed"
        top="0.5rem"
        width="100%"
        mb={8}
        sx={{
          backgroundColor: "black",
          zIndex: 10,
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "/",
            label: "Login",
            color: "white",
          }}
        />
      </MKBox>
      <MKBox component="section" mb={2} py={2} mt={12} sx={{ zIndex: 2, position: "relative" }}>
        <Container>
          <Grid item xs={12}>
            <MKTypography variant="h4" fontWeight="bold" mt={4} mb={2}>
              Privacy Policy
            </MKTypography>
            <MKTypography variant="h6" fontWeight="bold" mt={2} mb={2}>
              Last Updated: March 9, 2023
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              VidesiStays ("us", "we", or "our") operates the VidesiStays website (the "Service").
              This page explains our policies regarding the collection, use, and disclosure of
              Personal Information when you use our Service.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We are committed to safeguarding your information and will not share it with third
              parties except as described in this Privacy Policy. By using the Service, you consent
              to the collection and use of information in accordance with this policy. Terms used in
              this Privacy Policy have the same meanings as in our Terms and Conditions, which can
              be accessed on VidesiStays.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Information Collection and Use
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              While using our Service, we may ask you to provide certain personally identifiable
              information that can be used to contact or identify you. This may include, but is not
              limited to, your name, phone number, postal address, and other information ("Personal
              Information").
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Log Data
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We collect data sent by your browser when you visit our Service ("Log Data"). This may
              include information such as your computer’s Internet Protocol (IP) address, browser
              type and version, the pages you visit on our Service, the time and date of your visit,
              the time spent on those pages, and other statistics.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              You have the right to request that we stop processing your data or delete it. Please
              email us at contact@VidesiStays.com with your request.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Cookies
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Cookies are small files containing data, which may include an anonymous unique
              identifier. These files are sent to your browser from a website and stored on your
              computer’s hard drive.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We use cookies to collect information. You can configure your browser to refuse all
              cookies or to notify you when a cookie is being sent. However, if you choose not to
              accept cookies, some parts of the Service may not function properly.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Service Providers
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We may engage third-party companies and individuals to help facilitate our Service,
              provide the Service on our behalf, perform related services, or assist in analyzing
              how our Service is used.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              These third parties may access your Personal Information only to perform tasks for us
              and are obligated not to use or disclose it for any other purpose.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Security
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We value the security of your Personal Information. However, please be aware that no
              method of transmission over the Internet, or method of electronic storage, is
              completely secure. While we make every effort to use commercially acceptable means to
              protect your information, we cannot guarantee its absolute security.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Links to Other Sites
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Our Service may contain links to third-party websites that are not operated by us. If
              you click on a third-party link, you will be directed to that third party’s site. We
              strongly recommend reviewing the Privacy Policy of each website you visit.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We have no control over and assume no responsibility for the content, privacy
              policies, or practices of any third-party websites or services.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Children’s Privacy
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Our Service is not intended for anyone under the age of 13 ("Children").
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We do not knowingly collect personally identifiable information from children under
              13. If you are a parent or guardian and you become aware that your child has provided
              us with Personal Information, please contact us. If we discover that we have collected
              Personal Information from a child under 13, we will delete it from our servers
              immediately.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Changes to This Privacy Policy
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We may update this Privacy Policy periodically. Any changes will be posted on this
              page, and we will notify you of the updates.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We encourage you to review this Privacy Policy regularly for any updates. Changes to
              this Privacy Policy become effective when they are posted on this page.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              Contact Us
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              If you have any questions about this Privacy Policy, please feel free to contact us.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default PrivacyPolicy;
