import React, { useState } from "react";
import emailjs from "@emailjs/browser";

// @mui material components
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function ContactUs() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    preferredLocation: "",
    moveInDate: "",
    moveOutDate: "",
    describeNeed: "",
  });

  // State for status messages
  const [statusMessage, setStatusMessage] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, contactNo, preferredLocation, moveInDate, moveOutDate, describeNeed } =
      formData;

    // Validate form inputs
    if (
      !fullName ||
      !email ||
      !contactNo ||
      !preferredLocation ||
      !moveInDate ||
      !moveOutDate ||
      !describeNeed
    ) {
      setStatusMessage("Please fill in all the fields.");
      return;
    }

    try {
      // Configure EmailJS
      const serviceID = "service_irrnv5d";
      const templateID = "template_xnrorfm";
      const publicKey = "WiQj2QctmHM05fXg-";

      // Send email
      await emailjs.send(
        serviceID,
        templateID,
        {
          full_name: formData.fullName,
          email: formData.email,
          contact_no: formData.contactNo,
          preferred_location: formData.preferredLocation,
          move_in_date: formData.moveInDate,
          move_out_date: formData.moveOutDate,
          describe_need: formData.describeNeed,
        },
        publicKey
      );
      setStatusMessage("Message sent successfully!");
      setFormData({
        fullName: "",
        email: "",
        contactNo: "",
        preferredLocation: "",
        moveInDate: "",
        moveOutDate: "",
        describeNeed: "",
      });
    } catch (error) {
      console.error("Failed to send email", error);
      setStatusMessage("Failed to send the message. Please try again.");
    }
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "/",
            label: "Login",
            color: "info",
          }}
        />
      </MKBox>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
        mt={9}
      >
        <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={5}
            mb={5}
            mx={1}
            p={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including partnership opportunities, please email
                support@videsistays.com or contact us using our contact form.
              </MKTypography>
              <MKBox width="100%" component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="text"
                      variant="standard"
                      label="Contact No"
                      name="contactNo"
                      value={formData.contactNo}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="text"
                      variant="standard"
                      label="Preferred Location"
                      name="preferredLocation"
                      value={formData.preferredLocation}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="date"
                      variant="standard"
                      label="Move-In Date"
                      name="moveInDate"
                      value={formData.moveInDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="date"
                      variant="standard"
                      label="Move-Out Date"
                      name="moveOutDate"
                      value={formData.moveOutDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      type="text"
                      variant="standard"
                      label="Describe Your Need"
                      name="describeNeed"
                      value={formData.describeNeed}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Message
                  </MKButton>
                </Grid>
                {statusMessage && (
                  <MKTypography variant="subtitle2" color="info" align="center">
                    {statusMessage}
                  </MKTypography>
                )}
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
