/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

const TermsCondition = () => {
  return (
    <>
      <MKBox
        position="fixed"
        top="0.5rem"
        width="100%"
        mb={8}
        sx={{
          backgroundColor: "black",
          zIndex: 10,
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "/",
            label: "Login",
            color: "white",
          }}
        />
      </MKBox>
      <MKBox component="section" mb={2} py={2} mt={12} sx={{ zIndex: 2, position: "relative" }}>
        <Container>
          <Grid item xs={12}>
            <MKTypography variant="h4" fontWeight="bold" mt={4} mb={2}>
              Terms & Conditions
            </MKTypography>
            <MKTypography variant="h6" fontWeight="bold" mb={3}>
              Terms and Conditions of Website Use
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Please read these Terms and Conditions (&quot;Terms,&quot; &quot;Terms of Use&quot;)
              carefully before using the website www.videsistays.com (the &quot;Service&quot;)
              operated by videsistays (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;).
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              These Terms of Use, along with any referenced documents, outline the conditions under
              which you may access or use www.videsistays.com, its subdomains, or any associated
              websites and/or mobile applications (collectively referred to as our "Sites"), whether
              as a guest or a registered user. Accessing, browsing, or registering for an account on
              our Sites constitutes your agreement to abide by these Terms. If you log into our
              Sites via a third-party service like Facebook or Google, you will also be bound by
              these Terms upon accessing our Sites.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              By using this website, you confirm your acceptance of these Terms, whether or not you
              choose to register. If you do not agree with these Terms, you must refrain from using
              our Sites.
            </MKTypography>
            {/* Introduction Section */}
            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              1. Introduction
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              1.1 This website is operated by videsistays (the &quot;Company,&quot; &quot;we,&quot;
              &quot;us,&quot; or &quot;our&quot;) with its registered office located at 303, 4th
              Floor IT Building, DSL Virtue Mall Uppal Ring Road, Hyderabad, Telangana 500092.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              1.2 You can access most areas of this website without needing to register. However,
              certain sections of the site are only accessible to users who have registered.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              1.3 By accessing any part of this website, you agree to be bound by this legal notice
              in its entirety. If you do not agree to this legal notice in full, you must exit the
              website immediately.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              1.4 The Company reserves the right to update or revise this legal notice at any time
              by posting the updated version here. You should periodically check this website to
              review the current legal notice, as it remains binding on you. Certain sections of
              this legal notice may be replaced by specific legal notices or terms found on
              individual pages of the website.
            </MKTypography>

            {/* License Section */}
            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              2. Licence
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.1 You are allowed to print and download portions of this website for personal use,
              subject to the following conditions:
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.1.2 The graphics on this website must not be used independently of the accompanying
              text; and
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.1.3 The Company’s copyright, trademark notices, and this permission notice must
              appear in all copies.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.2 Unless stated otherwise, the copyright and other intellectual property rights for
              all content on this website (including, but not limited to, photographs and graphics)
              are owned by the Company or its licensors. Any use of website content outside the
              terms set out in clause 2.1 is strictly prohibited. If you violate any of the
              conditions in this legal notice, your permission to use the website will be
              automatically revoked, and you must immediately delete or destroy any downloaded or
              printed materials from the website.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.3 Except as allowed in clause 2.1, no part of this website may be reproduced, stored
              on another website, or included in any public or private electronic retrieval system
              or service without the prior written consent of the Company.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              2.4 All rights not explicitly granted in these terms are reserved.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              3. SERVICE ACCESS
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              3.1 Although the Company strives to ensure that this website is available 24 hours a
              day, it will not be held responsible if the website is unavailable at any time or for
              any duration.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              3.2 Access to this website may be temporarily suspended without prior notice due to
              system failures, maintenance, repairs, or factors beyond the Company’s control.{" "}
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              4. VISITOR MATERIAL AND CONDUCT
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.1 Except for personally identifiable information, which is governed by the Privacy
              Policy, any material you send or post to this website will be treated as
              non-confidential and non-proprietary. The Company has no obligations regarding such
              material. The Company and its affiliates are free to copy, disclose, distribute,
              incorporate, and otherwise use this material, along with any data, images, sounds,
              text, and other content contained within it, for any commercial or non-commercial
              purposes.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.2 You are prohibited from posting or transmitting any material to or from this
              website that:
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.2.1 is threatening, defamatory, obscene, indecent, seditious, offensive,
              pornographic, abusive, likely to incite racial hatred, discriminatory, menacing,
              scandalous, inflammatory, blasphemous, violates confidence, invades privacy, or causes
              annoyance or inconvenience;{" "}
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.2.2 you do not have the necessary licenses and/or approvals for;
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.2.3 encourages or constitutes conduct that would be considered a criminal offense,
              result in civil liability, or violate the laws of any country or infringe the rights
              of any third party;
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.2.4 contains harmful technical elements (including, but not limited to, viruses,
              logic bombs, Trojan horses, worms, corrupted data, or other malicious software).
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.3 You must not misuse the website, including, but not limited to, hacking or
              attempting unauthorized access.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.4 The Company will fully cooperate with law enforcement authorities or court orders
              that request or require the Company to disclose the identity or locate any individual
              posting material that violates clauses 4.2, 4.3, or 5.1.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.5 The website includes robot exclusion headers, and third parties may license
              information on the website to the Company. You agree not to use any automated tools,
              such as robots, spiders, or scrapers, to access the website for any purpose without
              explicit permission. Additionally, you agree not to:
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.5.1 Take any action that imposes, or may impose, in the Company’s sole discretion,
              an unreasonable or disproportionately large load on our infrastructure;
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.5.2 Copy, reproduce, modify, distribute, or publicly display any content (except for
              your own information) from the website without prior written consent from the Company
              or the relevant third party;{" "}
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.5.3 Interfere or attempt to interfere with the proper functioning of the website or
              activities conducted on it;{" "}
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              4.5.4 Bypass the robot exclusion headers or any other security measures we use to
              restrict access to the website.{" "}
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              5. YOUR ACCOUNT AND PASSWORD:{" "}
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              To register for an account on our Sites, you must be at least 18 years old at the time
              of registration, or if you are 13 or older, you must have the consent of a parent or
              guardian to create an account. You (or your parent or guardian acting on your behalf)
              must have the legal capacity to enter into a binding agreement with us and not be
              prohibited from doing so by any applicable laws.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              If you are given or choose a user ID, password, or any other security-related
              information to set up your account, you must keep this information confidential. You
              must not share it with any third party.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              We reserve the right to disable any user ID or password, whether chosen by you or
              provided by us, at any time if, in our reasonable judgment, you have violated
              applicable laws or any provisions of these terms, or if we believe your account is
              being used in an unauthorized or fraudulent manner.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              If you suspect that someone else knows your user ID or password, you must immediately
              notify us at contact@videsistays.com. After such notification, you may be required to
              create a new account with a new ID and/or password.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              6. AGREEMENT BETWEEN USERS OF OUR SITE
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.1 Our Sites provide a platform for property owners and managers (referred to as
              "Advertisers") to list their properties for potential student tenants (referred to as
              "Students").
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.2 You can use the Sites either as a guest or as a registered user. Once you find a
              property that meets your needs, you can submit an enquiry and complete a booking
              request form.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.3 The booking request form requires you to provide your name, email address, phone
              number, and details about your intended length of stay.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.4 At this stage, no payment information is needed, and there is no obligation to
              rent a property.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.5 After submitting the form, your assigned booking consultant will reach out to you
              to confirm and discuss your accommodation requirements. Communication may occur via
              email, phone, or WhatsApp.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.6 We do not own, manage, or directly arrange any rental properties listed on our
              Sites.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.7 We are not a party to any agreement between an Advertiser and a Student. The terms
              of any agreement between these parties may vary, and it is your responsibility to
              review and accept the Advertiser’s specific terms, including those related to payments
              and cancellations, if payment is made through our Sites.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.8 All aspects of the transaction between a Student and an Advertiser, such as the
              quality, safety, condition, and legality of the property, as well as the Student's
              ability to enter into the agreement, are solely the responsibility of the individual
              users. This includes the terms of any security deposit, which are set by the
              Advertiser. We do not represent or negotiate on behalf of either party, nor do we
              conduct research for them.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.9 We do not assume any responsibility for verifying the identity of either the
              Student or the Advertiser.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.10 If a third party, such as an education or travel agent, acts on behalf of a
              Student, it is the Student’s responsibility to ensure the accuracy of the information
              provided by the third party. We recommend that users take appropriate steps to
              directly communicate with the other party (whether Student or Advertiser) to verify
              their identity, details of the property, and any tenancy agreement.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              6.11 By submitting your contact information on our platform, you consent to
              Videsistays contacting you via email, phone, or SMS for assistance, as well as sending
              you marketing and promotional content.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              7. PRICES
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              7.1 The prices of properties displayed on our Sites are subject to change at any time.
              Despite our best efforts, there may be instances where the prices listed on the Sites
              are incorrect. We reserve the right to correct any pricing errors, whether on the
              Sites or in relation to bookings that have not yet been finalized.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              7.2 The prices shown are those provided by Advertisers. We are not responsible for the
              accuracy of these prices and, to the fullest extent permitted by law, we disclaim any
              liability for errors in the pricing displayed.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              7.3 Due to the global nature of our Sites, the currency in which prices are displayed
              may vary depending on your location. The exchange rates provided on the Sites are
              based on publicly available sources and should be treated as guidelines only. These
              rates are not guaranteed to be accurate, and actual prices may differ from those
              shown.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              7.4 Occasionally, third parties may offer promotions, special offers, or coupon codes
              on our Sites ("Coupons"). Each Coupon will have its own terms and conditions, which
              will apply in addition to these Terms. Coupons will be void if redeemed in violation
              of these Terms or the terms of the Coupon itself. Unless specifically stated on the
              Coupon, it cannot be combined with other discounts or promotions. Coupons are only
              valid during the specified promotional period, subject to availability. They are
              non-transferable and have no cash value.
            </MKTypography>
            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              8. PAYMENTS
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              8.1 In certain situations, you may be able to make payments to Advertisers through our
              Sites using payment providers like Airwallex. For more information about Airwallex,
              you can visit their website (link to Airwallex site). In these cases, it is your
              responsibility to familiarize yourself with the Advertiser's booking and cancellation
              policies.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              8.2 Any deposit paid to an Advertiser through our Sites is held by the Advertiser, not
              by Casita.com. Upon the conclusion of your tenancy, you will need to contact the
              Advertiser directly to arrange for the return of your deposit.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              9. CHANGES AND CANCELLATION
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Any tenancy agreement entered into will be between the Student and the Advertiser. It
              is the Student’s responsibility to familiarize themselves with the Advertiser's
              cancellation policy at the time of booking.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              <strong>9.1 Cancellation Policy</strong>
              <br />
              Cancellation policies may vary between providers. Below is the typical cancellation
              policy for the USA.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              <strong>Cancellations Before July 31st</strong>
              <br />
              If you have not yet taken occupancy of your accommodation (defined as when you collect
              the keys), you may cancel your booking before July 31st. In this case, your booking
              fee will be non-refundable and cannot be transferred to another customer or tenancy
              period. If the accommodation is assigned to another person without prior permission,
              strict legal actions may be taken, and the deposit will not be refunded. Once you
              cancel, you will no longer be liable for the contractual obligations set out in the
              Tenancy Agreement.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              <strong>Cancellations On or After July 31st</strong>
              <br />
              If you wish to cancel your booking on or after July 31st, regardless of whether you
              have collected your keys, you will remain liable for the terms of the Tenancy
              Agreement unless you can provide evidence that you meet any of the following criteria:
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              &nbsp;&nbsp;&nbsp; &#8226; If proof is provided within the specified timeframes, the
              cancellation will be approved, and you will no longer be liable for the contractual
              obligations of the Tenancy Agreement.
              <br /> &nbsp;&nbsp;&nbsp; &#8226; If the reason for cancellation cannot be
              substantiated, you will remain responsible for the full contractual rent until a
              replacement tenant is found for your accommodation.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              You are responsible for finding a suitable replacement tenant, though site staff may
              assist where possible. If a replacement tenant is found, you will be released from
              your contractual obligations, and any rent paid will be refunded, though your booking
              fee will be retained.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              Any decision to release a Customer outside of these criteria is at the discretion of
              the local or regional management team and will be considered on a case-by-case basis.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              10. INTELLECTUAL PROPERTY RIGHTS
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              10.1 We hold ownership or a license for all intellectual property rights related to
              our Sites and the content published on them. These works are safeguarded by copyright
              laws and international treaties (and/or similar intellectual property laws where
              applicable). All rights are reserved.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              11. LINKING TO OUR SITE
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              11.1 You may link to our homepage, provided that the link is fair, legal, and does not
              harm our reputation or exploit it in any way.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              11.2 You must not create a link that implies any form of affiliation, approval, or
              endorsement by us unless such an association exists. You may not link to our Sites
              from any website that you do not own.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              11.3 Our Sites must not be framed within another website, nor should you create links
              to any part of our Sites other than the homepage.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              11.4 We reserve the right to revoke linking permissions at any time, without notice.
              If you wish to use content from our Sites in any way other than outlined above, please
              contact us at contact@videsistays.com.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              12. THIRD PARTY LINKS AND RESOURCES ON OUR SITES
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              12.1 Our Sites may contain links to external websites and resources provided by third
              parties. These links are intended solely for your information.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              12.2 Any maps on our Sites provided by Google are governed by Google's current terms
              and conditions, which can be found at:{" "}
              <a
                href="http://www.google.com/intl/en/help/terms_maps.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              and{" "}
              <a
                href="https://developers.google.com/maps/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . We have no control over the content of these external sites or resources.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              13. CHANGES TO THE TERMS
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              13.1 We reserve the right to update these terms at any time by modifying this page. We
              will notify you of any changes through appropriate channels, such as relevant
              announcements on our homepage. If you do not agree with the updated terms, you must
              discontinue use of the Sites.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              14. DISCLAIMER
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              14.1 While the Company makes efforts to ensure that the information on this Website is
              accurate, it does not guarantee the accuracy or completeness of the content. The
              Company reserves the right to change the material on this Website, including the
              products, services, and/or prices described, at any time without prior notice. The
              content on this Website may become outdated, and the Company is under no obligation to
              update it.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              14.2 The content on this Website is provided "as is," without any warranties,
              conditions, or other terms. To the fullest extent permitted by law, the Company offers
              this Website on the basis that it excludes all representations, warranties,
              conditions, and other terms (including, but not limited to, implied conditions of
              satisfactory quality, fitness for purpose, and the use of reasonable care and skill)
              that would otherwise apply in relation to this Website.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              14.3 The commentary and other materials on this Website are not intended to be
              considered as advice on which reliance should be placed.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              15. LIABILITY
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.1 The Company, along with any other parties involved in creating, producing,
              maintaining, or delivering this Website, as well as its group companies and their
              officers, directors, employees, shareholders, or agents, disclaim all liability and
              responsibility for any loss or damage that may occur to you or a third party. This
              includes, without limitation, any direct, indirect, punitive, or consequential
              damages, or any loss of income, profits, goodwill, data, contracts, money, or damages
              arising from business interruption. This applies whether the loss or damage is based
              in tort (including negligence), contract, or any other legal theory, and in relation
              to the use, inability to use, or outcomes of using this Website, any websites linked
              to it, or the material on such websites. This includes, but is not limited to, loss or
              damage caused by viruses that may infect your computer, software, data, or other
              property as a result of accessing, using, or browsing this Website, or downloading
              materials from this Website or any linked websites.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.2 Nothing in this legal notice will exclude or limit the Company’s liability for:
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.2.1 Death or personal injury caused by the Company’s negligence;
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.2.2 Fraudulent misrepresentation; or
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.2.3 Any liability that cannot be excluded or limited under applicable law
              (including consumer protection laws).
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              15.3 If your use of materials on this Website requires servicing, repair, or
              correction of any equipment, software, or data, you are responsible for covering all
              associated costs.
            </MKTypography>

            <MKTypography variant="h6" fontWeight="bold" mt={4} mb={2}>
              16. FEATURED OFFERS
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              16.1 All promotional offers on the videsistays website are applicable once a student
              has checked into the property, unless otherwise specified.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              16.2 Offers provided in collaboration with property providers are subject to the terms
              and policies of the respective providers.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              16.3 Offers are non-transferable and are valid while supplies last.
            </MKTypography>
            <MKTypography variant="body3" color="textSecondary" component="p" mb={2}>
              16.4 videsistays reserves the right to modify, withdraw, or cancel any offer at any
              time.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default TermsCondition;
