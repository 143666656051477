/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";

// Image imports
import ComfortableLifestyle from "../../../assets/images/university/ComfortableLifestyle1.jpg";
import StayAssured from "../../../assets/images/university/StayAssured1.jpg";
import StressRelaxed from "../../../assets/images/university/StressRelaxed1.jpg";
import SmallerBills from "../../../assets/images/university/ReducedPayments1.jpg";
import comparisonData from "./data/comparisonData";
import MKButton from "components/MKButton";

function DesignBlocks() {
  return (
    <MKBox component="section" my={2} py={2}>
      {/* About Us Section */}
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="About Us"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Welcome to Videsi Stays
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Founded in 2023, Videsi Stays aims to provide affordable and comfortable housing for
            students. Our team is dedicated to creating a supportive environment that helps students
            thrive both academically and personally.
          </MKTypography>
        </Grid>
      </Container>

      {/* Stay Confident Section */}
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Text on Left, Image on Right */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              Stay Assured
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Moving to a new country can be an exciting experience, but it can also bring some
              stress, particularly when it comes to choosing the right place to stay. Whether
              you&apos;re in the USA for educational purposes or another reason, your accommodation
              should offer comfort, safety, and peace of mind from arrival to departure. Here&#39;s
              how to stay assured when booking and staying at accommodations across the United
              States. No matter where you go, you can be confident that your accommodations will be
              a true home away from home.
            </MKTypography>
            <MKBox>
              <MKButton variant="contained" color="primary" component={Link} to="/stayconfident">
                Know More
              </MKButton>
            </MKBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MKBox component="img" src={StayAssured} alt="Stay Assured" width="100%" />
          </Grid>
        </Grid>
      </Container>

      {/* Comfortable Living Section */}
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Image on Left, Text on Right */}
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <MKBox
              component="img"
              src={ComfortableLifestyle}
              alt="Comfortable Lifestyle"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              Comfortable Lifestyle
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Finding comfortable accommodations in the USA is all about choosing a place that suits
              your needs and provides the space, amenities, and comfort you desire. Whether it&#39;s
              cozy rooms, spacious apartments, modern facilities, or tailored services, there are
              countless options across the country designed to make you feel at home.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>

      {/* Stress-Free Stay Section */}
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Text on Left, Image on Right */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              Stress Relaxed
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Stay should be a time for relaxation, discovery, and enjoyment, not stress. Whether
              you&apos;re in the United States for educational purposes or another reason, your
              accommodation should provide a smooth, stress-free experience. Here&#39;s how to find
              hassle-free accommodations in the USA that will make your stay easy, comfortable, and
              enjoyable.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox component="img" src={StressRelaxed} alt="Stress Relaxed" width="100%" />
          </Grid>
        </Grid>
      </Container>

      {/* Reduced Payments Section */}
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Image on Left, Text on Right */}
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <MKBox component="img" src={SmallerBills} alt="Smaller Bills" width="100%" />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <MKTypography variant="h3" fontWeight="bold" mb={2}>
              Reduced Payments
            </MKTypography>
            <MKTypography variant="body2" color="text">
              With the right approach, you can enjoy affordable and comfortable accommodations in
              the USA without breaking the bank. By planning ahead, picking the best accommodation
              option for your needs, and being aware of hidden costs, you can keep your lodging
              expenses low and have more to spend on the activities and experiences that matter most
              to you.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>

      {/* Comparison Section */}
      <Container sx={{ mt: 8 }}>
        <MKTypography
          variant="h4"
          fontWeight="bold"
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          See how we compare to apartment living
        </MKTypography>
        <TableContainer component={Paper} sx={{ maxWidth: 800, mx: "auto", my: 4 }}>
          <Table sx={{ width: "100%" }}>
            <TableHead sx={{ display: "contents" }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold", width: "33%" }}>
                  Feature
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", width: "33%" }}>
                  Videsi Stays
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", width: "33%" }}>
                  Rental Property
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comparisonData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ width: "33%" }}>
                    {row.feature}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "33%" }}>
                    {row.videsiStays}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "33%" }}>
                    {row.rentalProperty}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
